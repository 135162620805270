@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap);

/* Standard syntax */
@keyframes inLeft {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(-100px, 0, 0);
    transform-origin: left center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes inLeft2 {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(-100px, 0, 0);
    transform-origin: left center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes inLeft2 {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(-100px, 0, 0);
    transform-origin: left center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes inLeft {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(-100px, 0, 0);
    transform-origin: left center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

/* Standard syntax */
@keyframes inRight {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(100px, 0, 0);
    transform-origin: right center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes inRight2 {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(100px, 0, 0);
    transform-origin: right center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes inRight {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(100px, 0, 0);
    transform-origin: right center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes inRight2 {
  from {
    opacity: 0;
    transform: scale(0.3) translate3d(100px, 0, 0);
    transform-origin: right center;
  }
  60% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.right {
  -webkit-animation: inRight 1.5s; /* Safari 4.0 - 8.0 */
  animation: inRight 1.5s;
}
.right2 {
  -webkit-animation: inRight2 1.5s; /* Safari 4.0 - 8.0 */
  animation: inRight2 1.5s;
}
.left {
  -webkit-animation: inLeft 1.5s; /* Safari 4.0 - 8.0 */
  animation: inLeft 1.5s;
}

.left2 {
  -webkit-animation: inLeft2 1.5s; /* Safari 4.0 - 8.0 */
  animation: inLeft2 1.5s;
}

.center {
  text-align: center;
}
.wrapper .text-form {
  width: 100%;
  text-align: center !important;
  /* font-family: 'Roboto Mono', monospace;
    font-weight: 600; */
  /* min-height: 450px; */
}

.quizbtnL {
  width: 380px !important;
  height: 450px !important;
  border: none !important;
  font-size: 18px !important;
  font-family: "Roboto Mono", monospace !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: black !important;
  border-radius: 50px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  position: relative !important;
  /* Safari 4.0 - 8.0 */
  /* -webkit-animation: inLeft 1.5s;
	animation: inLeft 1.5s; */
  vertical-align: top;
}

.quizbtnR {
  width: 380px !important;
  height: 450px !important;
  border: none !important;
  font-size: 18px !important;
  font-family: "Roboto Mono", monospace !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: black !important;
  border-radius: 50px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  position: relative !important;
  /* Safari 4.0 - 8.0 */
  /* -webkit-animation: inRight 1.5s; 
	animation: inRight 1.5s; */
  vertical-align: top;
}

.quizbtnR,
.btn,
.btn-secondary {
  outline: none;
}
.quizbtnR:hover,
.btn:hover,
.btn-secondary:hover {
  outline: none;
}

@media only screen and (max-width: 840px) {
  .quizbtnR {
    width: 360px !important;
    height: 350px !important;
    font-size: 15px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }
  .quizbtnL {
    width: 360px !important;
    height: 350px !important;
    font-size: 15px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .buttonText {
    height: 100px !important;
    width: 320px;
  }
}

@media only screen and (max-width: 800px) {
  .text-form {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 440px) {
  .quizbtnR {
    width: 90% !important;
    height: 290px !important;
    font-size: 13px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .quizbtnL {
    width: 90% !important;
    height: 350px !important;
    font-size: 13px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .buttonText {
    height: 100% !important;
    width: 200px !important;
  }
  .text-form {
    margin-top: 18%;
  }
}

/*  speech bubble */

@media only screen and (min-width: 1540px) {
  .speechBubble,
  .speechBubble2 {
    width: 50%;
    margin-top: -19%;
    margin-bottom: 4%;
    margin-right: 20%;

    /* height: 400px; */
  }
  .bubble,
  .bubble2 {
    width: 45%;
    margin-left: 3%;
  }

  .speechText,
  .speechText2 {
    width: 72%;
    margin-left: 18%;
    padding-top: 10px;
  }
}
/*  */
.speechBubble,
.speechBubble2 {
  width: 52%;
  margin-top: -19%;
  margin-bottom: 4%;
  margin-right: 18%;
  margin-left: auto;
  float: right;
}
.speechText {
  width: 75%;
  font-size: 23px !important;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  text-align: left;
  margin-left: 20%;
  padding-top: 10px;
  z-index: 20;
}
.speechText2 {
  font-size: 23px !important;
  width: 75%;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  text-align: left;
  margin-left: 20%;
  padding-top: 17px;
  z-index: 20;
}

.speechText3 {
  font-size: 23px !important;
  width: 75%;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  text-align: left;
  margin-left: 20%;
  padding-top: 17px;
  margin-bottom: -10%;
  z-index: 20;
}

.lineSpacer {
  height: 25px;
  width: 100%;
}

.lineSpacer2 {
  height: 65px;
  width: 100%;
}

.bubble,
.bubble2 {
  position: absolute;
  width: 50%;
  margin-left: 3%;
  z-index: -2;
}
/*  */

/* progress bar */
.progressBarDiv {
  text-align: center !important;
  width: 70%;
  margin-left: 20%;
  padding-top: 15%;
  z-index: 20;
}

.centerProgress {
  display: inline-block;
}

.success {
  color: #ed185b !important;
}

/* end speech */

.buttonText {
  height: 150px;
  width: 320px;
  position: absolute;
  top: 40px;
  text-align: left;
  margin-top: auto;
  font-weight: 400 !important;
  margin: 0 auto !important;
}

@media only screen and (max-width: 1250px) {
  .speechBubble {
    width: 52%;
    font-size: 19px !important;
    margin-top: -25%;
    margin-bottom: 4%;
    margin-right: 18%;
  }

  .speechBubble2 {
    width: 52%;
    margin-top: -23%;
    margin-bottom: 4%;
    margin-right: 18%;
  }
  .speechText,
  .speechText2,
  .speechText3 {
    width: 80%;
    font-size: 23px !important;
    margin-left: 30%;
  }
  .progressBarDiv {
    margin-top: -8%;
  }
}
@media only screen and (max-width: 1050px) {
  .speechBubble .speechBubble2 {
    width: 55%;
    margin-top: -25%;
    margin-right: 12%;
  }
  .speechText {
    width: 80%;
    margin-left: 19%;
    padding-top: 20px;
  }

  .speechText2 {
    width: 80%;
  }

  .bubble {
    width: 60%;
    margin-left: -1%;
  }
}
@media only screen and (max-width: 980px) {
  .speechBubble {
    width: 75%;
    margin-top: -25%;
    margin-bottom: 4%;
    margin-right: 17%;
  }

  .speechBubble2 {
    width: 75%;
    margin-top: -23%;
    margin-bottom: 4%;
    margin-right: 17%;
  }
  .progressBarDiv {
    margin-left: 31%;
  }
  .speechText,
  .speechText2 {
    width: 80%;
    margin-left: 29%;
    padding-top: 20px;
  }

  .bubble,
  .bubble2 {
    width: 70%;
    margin-left: 10%;
  }
}

/* Test keyframes to accomodate for 981 - 1000 */
@media only screen and (max-width: 1000px) {
  .speechBubble {
    width: 75%;
    margin-top: -25%;
    margin-bottom: 4%;
    margin-right: 17%;
  }

  .speechBubble2 {
    width: 75%;
    margin-top: -23%;
    margin-bottom: 4%;
    margin-right: 17%;
  }
  .progressBarDiv {
    margin-left: 31%;
  }
  .speechText,
  .speechText2 {
    width: 80%;
    margin-left: 29%;
    padding-top: 20px;
  }

  .bubble,
  .bubble2 {
    width: 70%;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 800px) {
  .speechText {
    width: 80%;
    margin-left: 29%;
    padding-top: 20px;
    font-size: 15px !important;
  }
  .speechText2 {
    font-size: 20px !important;
  }

  .bubble,
  .bubble2 {
    width: 76%;
    margin-left: 10%;
    padding-top: 3%;
  }
  .speechText3 {
    font-size: 20px !important;
  }

  /* queries for smaller screens up until ipad */
  /* ########################################### */

  .bgBlue {
    background-color: #3fb9f5 !important;
  }

  .bgGreen {
    background-color: #12b1aa !important;
  }

  .bgPurple {
    background-color: #8e7cc3 !important;
  }

  .bgPink {
    background-color: #e361a6 !important;
  }

  .bgRed {
    background-color: #e06666 !important;
  }

  .bgOrange {
    background-color: #e69138 !important;
  }

  .bgYellow {
    background-color: #f1c232 !important;
  }

  /* ########################################### */
}

@media only screen and (max-width: 515px) {
  .speechText,
  .speechBubble2 {
    width: 79%;
    font-size: 10px !important;
    margin-left: 23%;
  }
  .bubble,
  .bubble2 {
    width: 350px;
    /* height:  10em !important; */
    margin-left: 9%;
    padding-top: 3.5%;
  }

  .bubble {
    width: 350px;
    /* height:  10em !important; */
    margin-left: 5%;
    padding-top: 3.5%;
  }

  .lineSpacer2 {
    height: 35px;
    width: 100%;
  }
  .speechText2 {
    width: 80%;
    font-size: 15px !important;
    margin-left: 28%;
  }
  .speechText3 {
    font-size: 14px !important;
    width: 90%;
  }
}

@media only screen and (max-width: 375px) {
  .bubble,
  .bubble2 {
    width: 300px;
  }
  .speechText,
  .speechText2 {
    width: 90%;
    font-size: 10px !important;
    font-weight: 400;
    margin-left: 28%;
  }

  .progressBarDiv {
    width: 90%;
    margin-left: 27%;
    margin-top: -6%;
    margin-bottom: 9%;
  }
  .speechText3 {
    width: 90%;
    font-size: 10px !important;
  }
}

.lineSpacerA {
  height: 10px;
}

.bgBlue {
  background-color: #3fb9f5 !important;
}
.bgBlue:hover {
  background-color: white !important;

  /* border-color:#3fb9f5 !important;
  border:1px !important; */
}
.bgGreen {
  background-color: #12b1aa !important;
}
.bgGreen:hover {
  background-color: white !important;
}

.bgPurple {
  background-color: #8e7cc3 !important;
}
.bgPurple:hover {
  background-color: white !important;
}

.bgPink {
  background-color: #e361a6 !important;
}
.bgPink:hover {
  background-color: white !important;
}
.bgRed {
  background-color: #e06666 !important;
}
.bgRed:hover {
  background-color: white !important;
}
.bgOrange {
  background-color: #e69138 !important;
}
.bgOrange:hover {
  background-color: white !important;
}
.bgYellow {
  background-color: #f1c232 !important;
}
.bgYellow:hover {
  background-color: white !important;
}

@media only screen and (max-width: 1024px) {
  .bgBlue:hover {
    background-color: #3fb9f5 !important;
  }

  .bgGreen:hover {
    background-color: #12b1aa !important;
  }

  .bgPurple:hover {
    background-color: #8e7cc3 !important;
  }

  .bgPink:hover {
    background-color: #e361a6 !important;
  }
  .bgRed {
    background-color: #e06666 !important;
  }
  .bgRed:hover {
    background-color: #e06666 !important;
  }

  .bgOrange:hover {
    background-color: #e69138 !important;
  }

  .bgYellow:hover {
    background-color: #f1c232 !important;
  }
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}
/* ------------ end bachground images ------------ */
