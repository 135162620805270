@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap);


.background{
  background-color: inherit;
  width: 100%;
 }
 

.CSLogo {
  width: 100%;
  height: auto;
  margin-top: -10px;

}

.visionsLogo{
  width: 110%;
  height: auto;
  margin-top: -25px;
 }

 .full{
   width:100% !important;
 }
.footerBar {
  /* position: relative;
  left: 0;
  bottom: 0;
  width: 100%;  */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; 
  /* text-align:center !important; */
}

/* .footerText {
     font-size: 12px;
    color: #3061ac !important;
    font-family: 'Roboto Mono', monospace;
    margin: 0 10px;
     text-align: center;

    } */

 
    .logo-left{
      float: left;
      /* margin: -4% 10% 0% 0%; */
      margin: 0 auto;
       }
      
    .logo-right{
 
      float: right;
      /* margin: -3% 0% 0% 0% ; */
      margin: 0 auto;

    }

    .logo-center{
      /* float: right; */
      margin-right: 20%;
      margin-left: 20%;
     }

     .text-center-block{
       margin: auto;
     }

     .text-center{
       text-align: center;
     }

    /* .logo {
       padding-top: 5px;
        padding-left: 5px;
        display: inline-block; 
    } */

    /* .logo::after {
      content: '';
      clear: both;
      display: table;
    }  */


    /* @media only screen and (max-width: 600px) { 
   
.CSLogo {
  width: 70%;
  height: auto;
}

.visionsLogo{
  width: 120%;
  height: auto;
 }
   
} */
    

.privacy
{
  text-align: left;
  margin-top: 60px;
  margin-left: -30%;
 }

 .privacyLogo{
  text-align: center;
  margin-top: 60px;
 }
 


.color{
  color: black;
  font-family: 'Roboto Mono', monospace;
  font-weight:500;
  font-size: 13px;

}

.color:hover{
color: #ED185B;
}

@media only screen and (max-width: 760px) {

  .visionsLogo{
  margin-top: 15px;
  }
   
  .privacy
  {
    text-align: center; 
  }

  .logo-left{
       margin: 0 auto
       }
      
    .logo-right{
 
       margin: 0 auto;
   
    }
    .privacy
    {
      text-align: center;
      width: 200px;
      margin: 0 auto;
      margin-top: 60px !important;

    }

 
}