@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap);


.headerLogo {
  width: 300px;
  height: auto;
  float: left;
  display: inline;
 }

 .headerLogoB {
  width: 14%;
  /* height: auto; */
  float: left;
  display: inline;
  /* margin:80px 0px 0px 0px ; */
  margin: 4.5% 0% 0% 0% ;

 }

 .header{
   width:53%;
   height: auto;
   display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  }



.pull-left{
  float: left;
  font-size: 105%;
  color: #3061ac !important;
  font-family: 'Roboto Mono', monospace;
  /* margin:100px 5px 0px 25px ; */
 margin: 6% .7% 0% 2% ;
 
  }
.pull-right{
  float: right;
}

/* .row{
  display: table;
} */

@media only screen and (max-width: 766px) { 
  .headerLogo {
    width: 45%;
  }
  .navbar-collapse ul p {
     display: none;
    }
  .navbar-collapse ul {
    text-align: left; 
    margin-right: 16px;
  }
  .pull-left{
    float: left;
    font-size: 45%;
    color: #3061ac !important;
    font-family: 'Roboto Mono', monospace;
    /* margin:100px 5px 0px 25px ; */
     margin: 9% 0% 0% 2% ;
  }

  .headerLogoB {
    width: 18%;
    /* height: auto; */
    float: left;
    display: inline;
    /* margin:80px 0px 0px 0px ; */
    margin: 6.5% 0% 0% 0% ;
  
   }
  

}
.clearfix {
  padding-top: 30px;
  padding-left: 15px;
  
}
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.headerText {
  font-family: 'Roboto Mono', monospace;
  font-size: 100px;
  color: #3061ac;
}

.nav-item a {
  color: #3061ac !important;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  margin: 0 10px;
}
.nav-item a:hover {
  color: #FBA000 !important;
}

.navbarColor {
  color: white;
}

.ml-auto p {
  margin-top: 5px;
  font-size: 20px !important;
  color:#FBA000;
}

