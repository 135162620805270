/* allie 1 start */
.allie {
  max-height: 250px;
  width: auto; /* display: inline; */
  margin-left: 15%;
  margin-bottom: 1%;
}

@media only screen and (min-width: 1250px) {
  .allie {
    width: 17%;
    margin-bottom: 7%;
  }
}
@media only screen and (max-width: 1000px) {
  .allie {
    width: 16%;
    margin-left: 1%;
  }
}
@media only screen and (max-width: 765px) {
  .allie {
    width: 13%;
    margin-left: 0%;
  }
}
/* allie 1 finish */
