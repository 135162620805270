@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700&display=swap);

/* 
* INDEX
*
* Home Styles
* Main Styles
* Quiz Start Page
* Survey Intake Form Styles
* Results Page
* Category Information Component
* Rainbow Category Blocks Header
* About Page & Results Page Category Descriptions, & Resources
*
*/

/* ========== Home Styles ============ */
.hovered {background-color: #ED185B;}

.homePage {
    width: 70%;
    margin: auto;
    margin-top: 5px;
}
.homeTextWrapper {
    /* padding: 20px 50px !important; */
    border: none !important;
}
.homePhrase {
  color: black;
  font-family: 'Roboto Mono', monospace;
  font-weight:500;
  font-size: 34px;
  text-align: left;
}
.homeCTA {
  color: #2A5AB2;
  font-family: 'Roboto Mono', monospace;
  font-size: 50px;
  text-align: center;
}
.homeQuote {
  color: black;
  font-family: 'Roboto Mono', monospace;
  font-size: 21px;
  margin-top: 30px;
}
.quoteAuthor {
    font-family: 'Roboto Mono', monospace;
    font-size: 25px;
    text-align: left;
}
.homeGraphic {
  width: 100%;
  height: auto;
}

.homeButton {
    padding: 35px 40px !important;
    border-color: black;
    border-width: 1px;
    border-radius: 30px !important;
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    color: black !important;
    background: white !important;
    font-size: 30px;
 }

.homeButton:hover {
    background: white !important;
    color: black !important;
}
.lineSpacer{
    height:20px;
}
/* home responsiveness */
@media only screen and (max-width: 1024px) {
    .homePage {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    .homeCTA {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 425px) {
      .homePage {
          margin-top: 20px;
      }
  }
/* ========== QUIZ START PAGE =========== */


 

.intro{
     font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    color: black !important;
    font-size: 25px !important;
    text-align: justify;

}

.query{
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    color: black !important;
    font-size: 29px !important;
    text-align: left;
}

.quizStartButton{
    padding: 35px 40px !important;
    border-color: black;
    border-width: 1px;
    border-radius: 30px !important;
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    color: black !important;
    background: white !important;
 
 }

 .quizStartButton:hover{
    color: black !important;
    background: #ED185B!important;
 }
/* ======= SURVEY INTAKE FORM STYLES ======= */
.intake-form {
  width: 100% !important;
  margin: auto !important;
  padding: 0 20px !important;
  text-align: left !important;
  border-radius: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: #30BBF8 !important;
   border-width: 1;
}
.text-form {
  color: black !important;
  font-family: 'Roboto Mono', monospace;
  font-size: 15px !important;
  text-align: left !important;
}

.radio-text {
  font-size: 15px !important;
  border-color: #3fb9f5 !important;
  border-width: 1;
}


/* form width on mobile */

@media only screen and (max-width: 375px){
    .intro{
        text-align: left;
    }
}
@media only screen and (max-width: 736px) {
  .intake-form {
    width: 90%;
    padding: 0;
  }
}
.intake-form label {
  color: black;
  width: 30%;
}
.intake-form .gender {
  margin-bottom: -20px;
}
.intake-form .radio {
  width: 30%;
  text-align: center;
  display: inline;
  margin: 0;
}
.intake-form .radio:first-child {
  margin-left: 5px;
}
.intake-form div {
    margin: 20px 0px;
}
/* responsiveness of intake form */
@media only screen and (max-width: 1001px) {
  .intake-form .radio {
    width: 20%;
  }
}

/* ========= Results Page ========= */
.resultsButton {
    /* padding: 35px 40px !important; */
    border-color: black;
    border-width: 1px;
    border-radius: 30px !important;
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    color: black !important;
    background: white !important;
    width: 300px;
    height: 150px;
    font-size: 25px;
    margin:10px;
 }

.resultsButton:hover {
    color: black !important;
    background: #ED185B!important;
}

.inlineBtn{
    display: inline-block;
    width: 300px;
    height: 150px;
}

.resultsWrapper {
    font-family: 'Roboto Mono', monospace;
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    color: black;
    text-align: center;
}

.learnMoreButton {
    padding: 35px 35px !important;
    border-color: black !important;
    border-width: 1px;
    border-radius: 30px !important;
    font-family: 'Roboto Mono', monospace;
    font-weight: bold !important;
    color: black !important;
    background: white !important;
    margin: 10px;
 }

 .learnMore-container {
     /* display: flex; */
     align-content: center;
     justify-content: center;
     padding-top: 7rem;
     /* padding-bottom: 7rem; */
 }

 .SocialsContainer {
     margin-top: 3%;
     margin-bottom: 10%;
 }

 .red-divider {
    position: relative;
    width: calc(100% + 50px);
     background-color:#d72352;
     /* margin: 0; */
     left: -25px;
     }

 .program-info {
     width: 70%;
     margin: 0 auto;
     padding-top: 5%;
     text-align: left;
     font-size: 2rem;
 }



/* .yourResultsWrapper {
    min-height: 150px;
}
.yourResultsWrapper, .resultsChart {
    margin: 50px 0px;
    width: 45%;
    float:left;
} 
.yourResultsInner {
    width: 100%;
    margin: auto;
}*/
.impactAreas {
    clear: both;
    text-align: center;
}
/* .impactAreas h2 {
    margin-bottom: -20px;
} */
@media only screen and (max-width: 768px) {
    .resultsWrapper {
        width: 100%;
    } 
    .yourResultsWrapper, .resultsChart {
        float: none;
        width: 100%;
        clear: both;
        margin: 30px 0px;
    }
}

.text{
    color: white !important;
}
/* ===== Category Information Component */

.categoryBlocks_container{
max-width: 80%;
margin: 0 auto;
}

@media only screen and (min-width: 1722px) {
    .categoryBlocks_container{
        width: 1300px;
    }
}

.categoryInfoWrapper {
    margin-top: 50px;
    /* margin-bottom: 10%; */
}

@media only screen and (min-width: 1722px) {
    .resourcesText, .program-info, .categoryInfoWrapper
{
 width: 1300px !important;
margin: 0 auto;

}
.resourcesTitle{
    width: 1300px !important;
    margin: 0 auto !important;
    text-align: left !important;

}
}
.categoryWrapper {
    margin-bottom: 4%;
    border-radius: 30px;
    padding: 2% 5%;
}

.categoryEWD{
    border: 3px #3fb9f5 solid;
}

.categoryCCE{
    border: 3px #12B1AA solid;
}

.categoryCL{
    border: 3px #8E7CC3 solid;
}

.categoryESJ{
    border: 3px #E06666 solid;
}

.categoryTSSI{
    border: 3px #E361A6 solid;
}

.categorySRI{
    border: 3px #E69138 solid;
}

.categoryPJF{
    border: 3px #F1C232 solid;
}

.category h3 {
    text-align: left;
    font-size: 20px;
}

/* ===== Rainbow Category Blocks Header ====== */	

	.alignResults{
        text-align: center;
        color: black;
        display: inline-block;
        padding: 10px;
        }
        
    
        .alignAll{
            text-align: center;
            color: black;
            display: inline-block;
            margin: 0 auto;
        }
        
        .catText{
        width: 250px;
        /* height: 25px; */
        display: table-cell;  
        text-align: center;
        margin: 0 auto;
        font-size: 20px;
        }
        
        .resultText{
            width: 250px;
            /* height: 25px; */
            display: table-cell;
        }
        
    
        .categoryBlocks_wrapper {
            width: 1000px;
            height: auto;
            margin: auto;
            border: none;
        }
        
    
        .resultsCategoryBlock{
            width: 300px;
            height: 300px;
             padding: 25px 20px;
        }
        
        .resultsCategoryBlockText{
            width: 250px;
            display: table-cell;
            font-size: 20px;

        }
        .categoryBlock {
            width: 250px;
            height: 250px;
             padding: 25px 20px;
         }
        /* .categoryBlock p {
            font-size: 18px;
        } */
        
    
        
    
        #blue {
            background-image:url('../images/ewd.png') !important;
            display: inline-block;
            background-size: contain;
        
    
        }
        #green {
            background-image:url('../images/cc.png') !important;
            display: inline-block;
            background-size: contain;
        
    
             }
        #purple {
            background-image:url('../images/cl.png') !important;  
            display: inline-block;
            background-size: contain;
        
    
        }
        #pink {
            background-image:url('../images/tss.png') !important;
            display: inline-block;
            background-size: contain;
        
    
        }
        #red {
            background-image:url('../images/esj.png') !important;
            display: inline-block;
            background-size: contain;
        
    
        }
        #orange {
            background-image:url('../images/sri.png') !important;
            display: inline-block;
            background-size: contain;
        
    
        }
        #yellow {
            background-image:url('../images/pja.png') !important;
            display: inline-block;
            background-size: contain;
        
    
        }
        
    
        /* Responsiveness of Raninbow Category Blocks Header */
        @media only screen and (max-width: 1300px) {
            .categoryBlocks_wrapper {
                    width: 75%
                }
            .categoryBlock {
              width: 220px;
              height: 220px;
              padding: 25px 10px;
            }
            .categoryBlock p {
                font-size: 14px;
            }
         
        }

/* ========= About Page & Results Page Category Descriptions ========== */
.resourcesTitle{
    width: 50%;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 2%;
    /* padding-left: 30%; */
    margin-left: 15%;

}

.resourcesText{
    text-align: left;
    font-size: 25px;
    width: 70%;
    margin: 0 auto;
    padding-top: 1%;
   }

   .resourcesButton {
       padding: 40px 55px 40px 55px !important;
       border-color: black !important;
       border-width: 1px;
       border-radius: 30px !important;
       font-family: 'Roboto Mono', monospace;
       font-weight: bold !important;
       font-size: 25px !important;
       color: black !important;
       background: white !important;
       margin-left: 5px;
       margin-right: 5px;
     }

    @media only screen and (max-width: 880px) { 
        .resourcesButton{
            margin-top: 5px;
            margin-bottom: 5px;
            
                }


    }
        

.aboutWrapper, .categoryInfoWrapper, .resources {
    width: 70%;
    margin: auto;
    margin-top: 50px;
    color: black;
    font-family: 'Roboto Mono', monospace;
}
.aboutWrapper h3, .category h3, .resources h3 {
    text-align: left;
    color: #2A5AB2;
}
.resources h4 {
    color: black;
    text-align: center;
    margin: 30px 0px;
}
.aboutWrapper , .categoryInfoWrapper , .resources  {
    text-align: justify;
}
.aboutWrapper ul li, .category ul li, .resources ul li {
    text-align: left;
}
.aboutWrapper a, .categoryInfoWrapper a {
    color: #FBA000;
}
.categoryInfoWrapper hr {
    background-color: #2A5AB2;
    margin: 30px 0px;
}
@media only screen and (max-width: 375px) { 
    .aboutWrapper, .categoryInfoWrapper, .resources {
        width: 80%;
    }
}


/* Home page */

blockquote {
  background: #eee;
  border-radius: 5px;
}

blockquote p {
  padding: 15px;
}

blockquote p::before {
  content: '\201C';
}

blockquote p::after {
  content: '\201D';
}

.pageTitle {
  color: #36b8f8;
} 

.textHome {
  color: grey;
  font-size: 25px;
  font-family: 'Roboto Mono', monospace;
  text-align: center !important;
}


/* end Home page */


